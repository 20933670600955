import React, { useState } from 'react';

const RequestAccessForm = ( { onSubmit } ) => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        try {
            const response = await onSubmit(email);
            setSuccessMessage(response.data.message || `Thank you for your interest! We'll review your request and get back to you soon.`);
            setSubmitted(true);
        } catch (err) {
            console.log("Error:", err);
            if (err.response && err.response.status === 429) {
                setError('Too many requests. Please try again later.');
            } else if (err.response && err.response.data.errors) {
                setError(err.response.data.errors.map(error => error.msg).join(', '));
            } else if (err.response && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('There was an error submitting your request. Please try again later.');
            }
            setSubmitted(false);
        }
    };

    return (
        <div>
            {submitted ? (
                <div>
                    {!error && <p style={{ color: 'green' }}>{successMessage}</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit">Request Access</button>
                </form>
            )}
        </div>
    );
};

export default RequestAccessForm;