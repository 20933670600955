import React, { useState } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

function FileUpload({ onTextExtracted }) {
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axios.post(`${API_URL}/upload`, formData);
            const data = response.data;
            if (data.success) {
                onTextExtracted(data.fileContent);
            } else {
                throw new Error('Failed to upload file');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileUpload} accept='application/pdf' />
        </div>
    );
}

export default FileUpload;
