import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import '../App.css'

function FeedbackForm({ onSubmit, onClose }) {
    const [feedback, setFeedback] = useState('');
    const [subject, setSubject] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { currentUser } = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            setFeedback('');
            setSubject('');
            setConfirmationMessage('Thank you for your feedback!');
            setTimeout(() => {
                setConfirmationMessage('');
                onSubmit({ feedback, subject });
            }, 3000);
        } catch (err) {
            setErrorMessage('Could not submit feedback.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
    };

    return (
        <div>
            {currentUser && (
                <div className="feedback-dialog">
                <button className="close-btn" onClick={onClose}>X</button>
                <form onSubmit={handleSubmit}>
                    <label>
                    Subject:
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />
                    </label>
                    <label>
                    
                    <textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        required
                    />
                    </label>
                    <button type="submit">Submit Feedback</button>
                    {confirmationMessage && <p style={{ color: 'green' }}>{confirmationMessage}</p>}
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                </form>
            </div>
            )}
        </div>
    );
}

export default FeedbackForm;