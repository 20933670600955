import React, { useState } from 'react';
import { loginUser } from '../services/api';
import { useAuth } from '../context/AuthContext'; 
import { Navigate, useNavigate } from 'react-router-dom';

function LoginPage( { onLogin } ) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const data = await loginUser({ email, password });
        login(data);
        onLogin();
        navigate('/dashboard');
    } catch (err) {
        if (err.response && err.response.status === 400) {
          setErrors(err.response.data.errors);
        } else if (err.response && err.response.status  === 401) {
          setErrors([{ msg: err.response.data.message }]);
        } else {
          console.log('An unexpected error occurred:', err);
          setErrors([{ msg: 'An unexpected error occurred. Please try again.' }]);
        }
    }
  };

  return (
    <div>
      <h1>Mímir Quiz Generator <span style={{ color: 'red', fontSize: '18px' }}>Alpha version</span></h1>
      <form onSubmit={handleLogin}>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        {errors.length > 0 && (
          <div style={{ color: 'red' }}>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error.msg}</li>
              ))}
            </ul>
          </div>
        )}
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
