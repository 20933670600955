import React, { useState } from 'react';
import FileUpload from '../components/FileUpload';
import QuizGenerator from '../components/QuizGenerator';
import '../App.css'

function UploadFilePage() {
  const [fileText, setFileText] = useState(null);
  const [showQuiz, setShowQuiz] = useState(false);

  const handleFileUploadSuccess = (text) => {
    setFileText(text);
    setShowQuiz(true);
  };

  const handleReset = () => {
    setShowQuiz(false);
    setFileText(null);
  };

  return (
    <div className='wrapper'>
      <h1>Mímir Quiz Generator <span style={{ color: 'red', fontSize: '18px' }}>Alpha version</span></h1>
      <p>This is the alpha version of the build. All feedback is appreciated, as it will help us continue to improve the platform.</p>
      {!showQuiz && <p>Let's keep it simple.</p>}
      {!showQuiz && <p>1. Upload your notes in a PDF format. <br/>2. Generate a custom quiz. <br/>3. Take the quiz and discover what you know. <br/> 4. Retake and create new quizzes as many times as you want.</p>}
      {!showQuiz && <FileUpload onTextExtracted={handleFileUploadSuccess} />}
      {showQuiz && <QuizGenerator text={fileText} onReset={handleReset} />}
    </div>
  );
}

export default UploadFilePage;