import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';

const API_URL = process.env.REACT_APP_API_URL;

function QuizGenerator({ text, onReset }) {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [score, setScore] = useState(null);
    const [feedback, setFeedback] = useState([]);
    const [numQuestions, setNumQuestions] = useState(5);
    const [showSettings, setShowSettings] = useState(true);

    const handleGenerateQuiz = async () => {
        if (!text) {
            alert('No file uploaded or file ID missing.');
            return;
        }
        setIsLoading(true);
        setShowSettings(false);
        try {
            const response = await axios.post(`${API_URL}/generate-quiz`, { text, numQuestions });
            const questions = response.data.parsedQuestions;
            if (questions) {
                setQuestions(Array.isArray(questions) ? questions : [questions]);
                setAnswers({});
                setFeedback([]);
                setScore(null);
                setCurrentQuestionIndex(0);
            } else {
                throw new Error('Failed to generate quiz or no questions returned');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            alert('Error fetching quiz: ' + error.message);
            setQuestions([]);
            setShowSettings(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleRetakeQuiz = () => {
        setCurrentQuestionIndex(0);
        setAnswers({});
        setScore(null);
        setFeedback([]);
    };

    const handleNewFile = () => {
        onReset();
    };

    const handleSubmitQuiz = () => {
        const newFeedback = questions.map((question, index) => {
            const userAnswerLabel = answers[index];
            const userAnswer = question.options[userAnswerLabel];
            const correctAnswerLabel = question.correct_answer;
            const correctAnswer = question.options[correctAnswerLabel];
            const isCorrect = userAnswerLabel === correctAnswerLabel;
            return {
                question: question.question,
                userAnswer: userAnswer,
                correctAnswer: correctAnswer,
                isCorrect
            };
        });

        setFeedback(newFeedback);
        const calculatedScore = newFeedback.filter(f => f.isCorrect).length;
        setScore(calculatedScore);
    };

    return (
        <div className='App'>
          <div className='container'>
            {showSettings && (
              <div>
                <label htmlFor="numQuestions">Number of questions:</label>
                <select id='numQuestions' value={numQuestions} onChange={e => setNumQuestions(e.target.value)}>
                  {Array.from({ length: 20 }, (_, i) => i + 1).map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </div>
            )}
            {showSettings && ( 
              <button onClick={handleGenerateQuiz} disabled={!text || isLoading}>
                {isLoading ? 'Loading...' : 'Generate Quiz'}
              </button>
            )}
            {isLoading ? 'Loading...' : '' }
            {questions.length > 0 && (
              <div>
                {score == null && (
                  <div>
                    <div>
                      <h4>{questions[currentQuestionIndex].question}</h4>
                      <ul>
                        {Object.entries(questions[currentQuestionIndex].options).map(([key, value]) => (
                          <li key={key} onClick={() => setAnswers({ ...answers, [currentQuestionIndex]: key })}
                              style={{ cursor: 'pointer', color: answers[currentQuestionIndex] === key ? 'cyan' : 'white' }}>
                              {key}: {value}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <button onClick={handlePrevious} disabled={currentQuestionIndex === 0}>Previous</button>
                      <button onClick={handleNext} disabled={currentQuestionIndex === questions.length - 1 || answers[currentQuestionIndex] == null}>Next</button>
                    </div>
                    {currentQuestionIndex === questions.length - 1 && (
                      <div>
                        <button onClick={handleSubmitQuiz} disabled={answers[currentQuestionIndex] == null}>Submit Quiz</button>
                      </div>
                    )}
                  </div>
                )}
                {score !== null && !isLoading && <p>Your score: {score}/{questions.length}</p>}
                {feedback.length > 0 && !isLoading && (
                  <ul>
                    {feedback.map((item, index) => (
                      <li key={index}>
                        {index + 1}. {item.question}
                        <br />
                        <br />
                        Your answer: <span style={{ color: item.isCorrect ? 'green' : 'red' }}>{item.userAnswer}</span>
                        <br />
                        {!item.isCorrect && (
                          <div>
                            Correct answer: {item.correctAnswer}
                          </div>
                        )}
                      </li>
                    ))}
                    <div>
                      <button onClick={handleRetakeQuiz}>Retake Quiz</button>
                      <button onClick={handleGenerateQuiz}>Generate new quiz</button>
                      <button onClick={handleNewFile}>Upload new file</button>
                    </div>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      );
}

export default QuizGenerator;
