import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { RedirectIfLoggedIn } from './components/RedirectIfLoggedIn';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';
import UploadFilePage from './pages/UploadFilePage';
import FeedbackForm from './components/FeedbackForm';
import RequestAccessForm from './components/RequestAccessForm';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

function App() {

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showFeedbackButton, setShowFeedbackButton] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);

  const handleFeedbackSubmit = async (data) => {
    setShowFeedbackForm(false);
    try {
      const response = await axios.post(`${API_URL}/submit-feedback`, data);
      console.log('Feedback submitted:', response.data);
    } catch (err) {
      console.error('Error submitting feedback:', err);
    }

  };

  const handleRequestSubmit = async (data) => {
    try {
      const email = data
      const response = await axios.post(`${API_URL}/request-access`, { email });
      return response;
    } catch (err) {
      throw err;
    }
  };

  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const toggleFeedbackButton = () => {
    setShowFeedbackButton(!showFeedbackButton);
  };

  const toggleRequestForm = () => {
    setShowRequestForm(!showRequestForm);
  };

  return (
    <AuthProvider>
      <Router>
        <div>
          <LogoutButton toggleFeedbackButton={toggleFeedbackButton}/>
        {!showFeedbackForm && showFeedbackButton && (
          <button
          onClick={toggleFeedbackForm}
          style={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            padding: '10px 20px'
          }}
        >
          Give Feedback
        </button>
        )}
      {showFeedbackForm && (
        <FeedbackForm
          onSubmit={handleFeedbackSubmit}
          onClose={toggleFeedbackForm}
          className={showFeedbackForm ? "feedback-dialog open" : "feedback-dialog"}
        />
      )}
          <nav>
            <ul>
            </ul>
          </nav>
          <Routes>
            <Route path='/' element={<RedirectIfLoggedIn redirectTo='/dashboard'><LoginPage onLogin={toggleFeedbackButton} /></RedirectIfLoggedIn>} />
            <Route path='/dashboard' element={<ProtectedRoute><UploadFilePage /></ProtectedRoute>} />
            <Route path="/" element={<UploadFilePage />} />
          </Routes>
          <RequestButton toggleRequestForm={toggleRequestForm} showRequestForm={showRequestForm}/>
        {showRequestForm && !showFeedbackButton && (
          <RequestAccessForm onSubmit={handleRequestSubmit}/>
        )}
        </div>
      </Router>
    </AuthProvider>
  );
}

const LogoutButton = ( {toggleFeedbackButton} ) => {
  const { currentUser, logout } = useAuth();

  return (
    <div>
      {currentUser && (
      <button style={{ float: 'right' }} onClick={() => { logout(); toggleFeedbackButton(); }}>Logout</button>
    )}
    </div>
  );
};

const RequestButton = ( { toggleRequestForm, showRequestForm } ) => {
  const { currentUser } = useAuth();

  return (
    <div>
      {!currentUser && !showRequestForm && (
        <button onClick={toggleRequestForm}>
        Request access
      </button>
      )}
    </div>
  )
}

export default App;
